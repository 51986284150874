import React from 'react';
import { useState } from 'react';
import "./YourSummary.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SliderWithSideButtonSingle from '../YourChoice/SliderWithSideButtonSingle'
import { useHistory, Link } from 'react-router-dom';

import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';

import { alpha, styled } from '@mui/material/styles';

import { colorTheme } from "../AboutYou/AboutYou.js"

const GadSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colorTheme,
      '&:hover': {
        backgroundColor: alpha(colorTheme, theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colorTheme,
    },
  }));


function Infographic({  scheme, 
                        currentPanel, 
                        setCurrentPanel, 
                        index, 
                        age, 
                        commutationProportion1987,
                        commutationProportion2015,
                        prevSchemePensionNormalised,
                        currSchemePensionInPaymentNormalised,
                        currSchemePensionLaterPaymentNormalised,
                        maxCommutationPrevScheme,
                        maxCommutationCurrScheme,
                        prevSchemePension,
                        currSchemePensionInPayment,
                        currSchemePensionLaterPayment,
                        lumpSum1987,
                        lumpSum2015,
                        maxLumpSum, 
                        colourLumpSumBorder, 
                        colourLumpSumFill,
                        infographicWidth, 
                        colour1987Fill, 
                        colour2015Fill, 
                        colour1987Border, 
                        colour2015Border,
                        commutationProportion1987LumpSum,
                        commutationProportion2015LumpSum, }) { 
        
        const transitionYears = ['2022','2015']

        const selectText = () => {
            setCurrentPanel(index)
        }

        return <div>
        <div className="infographic__bar__container">
        {transitionYears.map( (transitionYear, i) => (
            <InfographicBar
            scheme={scheme}
            scenario={index}
            index={i} callbackCurrentPanel={setCurrentPanel} currentPanel={currentPanel}
            age={age}
            annualPension1987Scaled={(1-commutationProportion1987/4)*prevSchemePensionNormalised[transitionYear]} 
            annualPension2015Scaled={(1-commutationProportion2015/4)*currSchemePensionInPaymentNormalised[transitionYear]} 
            annualPension2015LaterScaled={(1-commutationProportion2015/4)*currSchemePensionLaterPaymentNormalised[transitionYear]}
            annualPension1987={ (1-commutationProportion1987*maxCommutationPrevScheme) * prevSchemePension[transitionYear]}
            annualPension2015={ (1-commutationProportion2015*maxCommutationCurrScheme) * currSchemePensionInPayment[transitionYear]}
            annualPension2015Later={ (1-commutationProportion2015*maxCommutationCurrScheme) * currSchemePensionLaterPayment[transitionYear]}
            annualPensionHeight={400} infographicWidth={120}
            lumpSumScaled={0.5}
            maxLumpSum = {maxLumpSum[transitionYear]}
            lumpSum = {commutationProportion1987LumpSum*lumpSum1987[transitionYear]+commutationProportion2015LumpSum*lumpSum2015[transitionYear]}
            colour1987Fill={colour1987Fill} colour2015Fill={colour2015Fill} colour1987Border={colour1987Border} colour2015Border={colour2015Border}
            lumpSumFill={colourLumpSumFill} lumpSumBorder={colourLumpSumBorder} transitionYear={transitionYear}
            />
        ))}
        </div>
        <div className="infographic__icon" width={infographicWidth} align="center">
                <Typography sx={{display:'block',textAlign:'center',fontSize:'16px', fontWeight:'bold', border: currentPanel === index && 'solid #af292e 1px'}} onClick={selectText}>
                    Age {age}  
                </Typography>

            {currentPanel === index && ( <ArrowDownwardOutlinedIcon sx={{ 
                margin: "4px",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "4px",
                boxSizing: "content-box",
                color: "white", 
                backgroundColor: "#af292e", 
                fontSize:"20" }}/>)}
        </div>
        
    </div> 
};


function InfographicBar({ scheme, scenario, currentPanel, callbackCurrentPanel, index, age, annualPension1987Scaled, annualPension2015Scaled ,
                        annualPension2015LaterScaled,
                        annualPension1987, annualPension2015, annualPension2015Later,
                        maxLumpSum, lumpSum, lumpSumBorder, lumpSumFill,
                        annualPensionHeight, infographicWidth, colour1987Fill, colour2015Fill, colour1987Border, colour2015Border, transitionYear }) { 
    
    const marginLeft = 10
    const width = infographicWidth
    const lumpSumCenter = width*0.5 + marginLeft
    const textMarginLeft = lumpSumCenter
    const marginLeftLater = 40
    
    const totalHeight = annualPensionHeight + infographicWidth + 100
    const baseHeight = totalHeight - 30

    const height1987 = annualPension1987Scaled*annualPensionHeight
    const height2015 = annualPension2015Scaled*annualPensionHeight
    const height2015Later = annualPension2015LaterScaled*annualPensionHeight
    
    const minRadius = width*0.3
    const radius = minRadius + (lumpSum/maxLumpSum)*width*0.2

    const selectText = () => {
        callbackCurrentPanel(scenario)
    }

    return <svg style={{cursor:"pointer"}} width = {infographicWidth*1.5} height = { totalHeight + 10} onClick={selectText}>
                    {/* <g>
                        {currentPanel === index && (
                            <rect x={marginLeft} width={width} y = {totalHeight-25} height={20} stroke="#af292e" fill="none"/>
                        )}
                        <text x={lumpSumCenter} y = {totalHeight-10} textAnchor="middle" fontFamily="Verdana" fontSize="13" fontWeight="bold" fill="black"> {`Age ${age}`} </text>

                        
                    </g> */}
                    <g>
                        <text x={lumpSumCenter} y = {totalHeight-10} textAnchor="middle" fontFamily="Verdana" fontSize="13" fontWeight="bold" fill="black"> {transitionYear==='2015'?'Reformed':'Legacy'} </text>  
                    </g>
                    <g>      
                        {/* lumpSum */}
                        
                        {lumpSum > 0 ? (
                            <g>
                                <circle cx={lumpSumCenter} cy = {100} r={radius} fill={lumpSumFill} stroke={lumpSumBorder} opacity={currentPanel === scenario ? ("1.0") : ("0.5")}/>
                                <text x={lumpSumCenter} y = {95} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">Cash</text>
                                <text x={lumpSumCenter} y = {115} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">
                                    {"£" + Math.round(`${lumpSum}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </text>
                            </g>
                            ):
                            (
                            <g>
                                <circle cx={lumpSumCenter} cy = {100} r={radius} fill="grey" stroke="black" opacity={currentPanel === scenario ? ("1.0") : ("0.5")}/>
                                <text x={lumpSumCenter} y = {95} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">No</text>
                                <text x={lumpSumCenter} y = {110} textAnchor="middle" fontFamily="Verdana" fontSize="10" fill="white">Lump sum</text>
                            </g> 
                            )
                        }
                        
                    </g>

                    <Tooltip placement="right" title={
                        <Typography sx={{display:'block',textAlign:'center',fontSize:'16px'}}>
                            <Typography>{scheme} Pension</Typography>
                            <Typography>{"£" + Math.round(`${annualPension1987}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}</Typography>                            
                        </Typography>

                    } >
                    <g>      
                        {/* 1987 */}
                        <rect 
                        x={marginLeft}
                        width={width}
                        height={height1987}
                        fill={colour1987Fill}
                        stroke={colour1987Border}
                        opacity={currentPanel === scenario ? ("1.0") : ("0.5")}
                        y = {baseHeight - height1987}
                        />
                        {height1987 > 30 && ( <g>
                        <text x={textMarginLeft} y = {baseHeight - height1987 + height1987/3} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">{scheme} Pension</text>
                        <text x={textMarginLeft} y = {baseHeight - height1987 + height1987/3 + 20} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="white">
                            {"£" + Math.round(`${annualPension1987}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}
                        </text>
                        </g>)}
                    </g>
                    </Tooltip>
                    
                    <Tooltip placement="right" title={
                        <Typography sx={{display:'block',textAlign:'center',fontSize:'16px'}}>
                            <Typography>2015 Pension</Typography>
                            <Typography>{"£" + Math.round(`${annualPension2015}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}</Typography>                            
                        </Typography>

                    } >
                    <g>      
                        {/* 2015 */}
                        <rect 
                        x={marginLeft}
                        width={width}
                        height={height2015}
                        fill={colour2015Fill}
                        stroke={colour2015Border}
                        opacity={currentPanel === scenario ? ("1.0") : ("0.5")}
                        y = {baseHeight - height1987 - height2015}
                        />
                        {annualPension2015 > 0 && (
                            <g>
                                <text x={textMarginLeft} y = {baseHeight - height1987 - height2015 - 20} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="black">2015 Pension</text>
                                <text x={textMarginLeft} y = {baseHeight - height1987 - height2015 - 5} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="black">
                                    {"£" + Math.round(`${annualPension2015}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}
                                </text>
                            </g>
                            )
                        }
                    </g>
                    </Tooltip>

                    <Tooltip placement="right" title={
                        <Typography sx={{display:'block',textAlign:'center',fontSize:'16px'}}>
                            <Typography>2015 Pension</Typography>
                            <Typography>paid at age 55</Typography>
                            <Typography>{"£" + Math.round(`${annualPension2015Later}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}</Typography>                            
                        </Typography>

                    } >
                    <g>      
                        {/* 2015 Later */}
                        <rect 
                        x={marginLeftLater}
                        width={width}
                        height={height2015Later}
                        fill={colour2015Fill}
                        stroke={colour2015Border}
                        opacity={currentPanel === scenario ? ("1.0") : ("0.5")}

                        y = {baseHeight - height1987 - height2015 - height2015Later}
                        />
                        {annualPension2015Later > 0 && (
                            <g>
                                <text x={marginLeftLater+width*0.5} y = {baseHeight - height1987 - height2015 - height2015Later - 35} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="black">2015 Pension</text>
                                <text x={marginLeftLater+width*0.5} y = {baseHeight - height1987 - height2015 - height2015Later - 20} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="black">paid at age 55</text>
                                <text x={marginLeftLater+width*0.5} y = {baseHeight - height1987 - height2015 - height2015Later - 5} textAnchor="middle" fontFamily="Verdana" fontSize="13" fill="black">
                                    {"£" + Math.round(`${annualPension2015Later}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" p.a."}
                                </text>
                            </g>
                            )
                        }
                    </g>
                    </Tooltip>
                </svg>  
};

function YourSummary( {scheme, showJourneyInfographic, prevSchemePension, currSchemePensionInPayment, currSchemePensionLaterPayment,
    prevSchemePensionNormalised, currSchemePensionInPaymentNormalised, currSchemePensionLaterPaymentNormalised,
    lumpSum1987, lumpSum2015, introduction, careIntroduction, finalSalaryCommutation, careCommutation,
    colour1987Fill, colour1987Border, colour2015Fill, colour2015Border, colourLumpSumFill, colourLumpSumBorder,
    commutationProportion1987, callbackCommutationProportion1987, commutationProportion2015, callbackCommutationProportion2015, colourButton, retirementAges,
    maxCommutationPrevScheme, maxCommutationCurrScheme, titles, transitionYear, setTransitionYear,
    prevSchemePensionEqualised, currSchemePensionInPaymentEqualised, currSchemePensionLaterPaymentEqualised,
    prevSchemePensionNormalisedEqualised, currSchemePensionInPaymentNormalisedEqualised, currSchemePensionLaterPaymentNormalisedEqualised,
    lumpSum1987Equalised, lumpSum2015Equalised, equalised, callbackEqualised, careCommutationEqualised } ) {
        
    const history = useHistory();
    const [currentPanel, setCurrentPanel] = useState(0);

    scheme === '2006' && callbackCommutationProportion1987(1);

    const commutation1987Information = <p> Members are able to exchange (commute) some of their pension for a cash lump sum. The amount of pension that can be commuted in the legacy Scheme is subject to the limits set out in the Regulations. Please refer to the Regulations for full details of the commutation limits. This calculator applies the limits described above.<br/><br/>Please note that restrictions on pension commutation are also imposed by the pension taxation regime under Finance Act 2004. The main taxation restriction is that for a lump sum to be authorised under the tax rules it must not exceed 25% of the total value of benefits crystallised. Please refer to HMRC guidance for further information. This calculator does not make any allowance for the pensions tax regime.</p>

    const commutation2015Information = <p>Members are generally able to exchange (commute) up to 35.7% of their 2015 Scheme pension for a cash lump sum (limits set by HMRC). Setting this slider to “100” will therefore illustrate the lump sum and reduced pension you will receive after commuting 35.7% of your 2015 Scheme pension.</p>

    {scheme === '1987' && callbackEqualised(false)}

    const handleSubmit = () => {
        
        history.push("your choice")
    }
    const doNothing = () => {

    }
    const handleEqualised = (e) => {
        {e.target.checked  ? callbackEqualised(true) : callbackEqualised(false)}
    }

    let maxLumpSum = {'2015':0, '2022':0}; 
    let transitionYears = ['2015','2022'];

    for (let i = 0; i < lumpSum1987.length; i++) {
        transitionYears.forEach( (ty) => {
          if (lumpSum1987[i][ty]+lumpSum2015[i][ty] > maxLumpSum[ty]){
                maxLumpSum[ty] = lumpSum1987[i][ty]+lumpSum2015[i][ty]
            }   
        })
    }

    let maxLumpSumEqualised = {'2015':0, '2022':0}; 

    for (let i = 0; i < lumpSum1987Equalised.length; i++) {
        transitionYears.forEach( (ty) => {
          if (lumpSum1987Equalised[i][ty]+lumpSum2015Equalised[i][ty] > maxLumpSumEqualised[ty]){
                maxLumpSumEqualised[ty] = lumpSum1987Equalised[i][ty]+lumpSum2015Equalised[i][ty]
            }   
        })
    }
    //console.log({'maxlumpsumEqualised': maxLumpSumEqualised })

    console.log(commutationProportion2015)

    let commutationPrevSchemeLabel = "Cash lump sum in " + scheme + " scheme (%)";

    return (
        <div className="graph__container" >
            {showJourneyInfographic ? (
                <div>

 

                    <div className="title__container">
                        <h3> Pension and lump sum amounts at Key Milestones </h3>
                        <p>The chart below illustrates the annual pension and lump sum you may receive from both the {scheme} and 
                        2015 Schemes at different retirement ages depending on whether you remain in your legacy scheme 
                        or move to the reformed scheme for service between 1 April 2015 to 31 March 2022. Figures are shown in today’s money terms.
                        </p>
                        <p>The scenarios below use an illustrative set of assumptions for CARE revaluation (3.25%) and earnings 
                            inflation (3.25%). As these factors can impact the outcome of which scheme provides higher benefits 
                            during the remedy period, the <Link to="/your choice">Your Choice</Link> section includes functionality to vary the relationship 
                            between these assumptions by changing the earnings inflation assumption.</p>
                    </div>

                    <div className="description__container">
                            <div className="description__payments">
                                <h3>Click on any of the scenarios below to see further information in the text box below the image</h3>
                                <h3>Please use the sliders to the {prevSchemePension.length > 3 ? "top" : "right"} of the chart to see how your pension and lump sum amounts change based on how much lump sum you wish to take</h3>
                            </div>
                    </div>

                    <div className={prevSchemePension.length > 3 ? "infographic__container__parent__column" : "infographic__container__parent__row"}>    
                        <div className = "infographic__container">            
                        {!equalised && prevSchemePension.map( (prevPension, i) => 
                            <div className="infographic" key={i}>
                                <Infographic
                                scheme={scheme}
                                index={i} setCurrentPanel={setCurrentPanel} currentPanel={currentPanel}
                                age={retirementAges[i]}

                                commutationProportion1987={commutationProportion1987}
                                commutationProportion2015={commutationProportion2015}
                                prevSchemePensionNormalised={prevSchemePensionNormalised[i]}
                                currSchemePensionInPaymentNormalised={currSchemePensionInPaymentNormalised[i]}
                                currSchemePensionLaterPaymentNormalised={currSchemePensionLaterPaymentNormalised[i]}
                                maxCommutationPrevScheme={maxCommutationPrevScheme[i]}
                                maxCommutationCurrScheme={maxCommutationCurrScheme[i]}
                                prevSchemePension={prevSchemePension[i]}
                                currSchemePensionInPayment={currSchemePensionInPayment[i]}
                                currSchemePensionLaterPayment={currSchemePensionLaterPayment[i]}
                                lumpSum1987={lumpSum1987[i]}
                                lumpSum2015={lumpSum2015[i]}

                                annualPensionHeight={400} 
                                infographicWidth={120}
                                lumpSumScaled={0.5}

                                maxLumpSum = {maxLumpSum}

                                colour1987Fill={colour1987Fill} 
                                colour2015Fill={colour2015Fill} 
                                colour1987Border={colour1987Border} 
                                colour2015Border={colour2015Border}
                                colourLumpSumFill={colourLumpSumFill} 
                                colourLumpSumBorder={colourLumpSumBorder}

                                commutationProportion1987LumpSum={commutationProportion1987}
                                commutationProportion2015LumpSum={commutationProportion2015}
                                />
                            </div>
                            )
                        }
                        

                        {equalised && prevSchemePension.map( (prevPension, i) => 
                            <div className="infographic" key={i}>
                                <Infographic
                                scheme={scheme}
                                index={i} setCurrentPanel={setCurrentPanel} currentPanel={currentPanel}
                                age={retirementAges[i]}

                                commutationProportion1987={0}
                                commutationProportion2015={0}
                                prevSchemePensionNormalised={prevSchemePensionNormalisedEqualised[i]}
                                currSchemePensionInPaymentNormalised={currSchemePensionInPaymentNormalisedEqualised[i]}
                                currSchemePensionLaterPaymentNormalised={currSchemePensionLaterPaymentNormalisedEqualised[i]}
                                maxCommutationPrevScheme={1}
                                maxCommutationCurrScheme={1}
                                prevSchemePension={prevSchemePensionEqualised[i]}
                                currSchemePensionInPayment={currSchemePensionInPaymentEqualised[i]}
                                currSchemePensionLaterPayment={currSchemePensionLaterPaymentEqualised[i]}
                                lumpSum1987={lumpSum1987Equalised[i]}
                                lumpSum2015={lumpSum2015Equalised[i]}

                                annualPensionHeight={400} 
                                infographicWidth={120}
                                lumpSumScaled={0.5}

                                maxLumpSum = {maxLumpSumEqualised}

                                colour1987Fill={colour1987Fill} 
                                colour2015Fill={colour2015Fill} 
                                colour1987Border={colour1987Border} 
                                colour2015Border={colour2015Border}
                                colourLumpSumFill={colourLumpSumFill} 
                                colourLumpSumBorder={colourLumpSumBorder}

                                commutationProportion1987LumpSum={1}
                                commutationProportion2015LumpSum={1}
                                />
                            </div>
                            )
                        }       
                        </div>

                    <div className="slider__container__summary">

                        {scheme !== '1987' && 
                            <div>
                                <FormControlLabel control={
                                    <GadSwitch 
                                        checked={equalised}
                                        onChange={handleEqualised}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    } label={<Typography sx={{display:'block',textAlign:'left',fontSize:'16px', fontWeight:'bold'} }>
                                    Equalise lump sums
                                    </Typography>}
                                    labelPlacement='start' 
                                />
                                
                                <Tooltip title={<Typography fontSize={'16px'}>
                                    When the switch is turned on, it is assumed that no commutation is taken in the legacy scenario 
                                    and that the commutation amount taken in reformed scheme scenario is such that total lump 
                                    sums are equal in both scenarios.</Typography>}>
                                    <IconButton id="switch__infobutton" >
                                        <InfoIcon id="switch__infobutton" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                        
                        {scheme !== '1987' &&<br></br>}

                        {!equalised && maxCommutationPrevScheme[0] > 0 &&
                        <SliderWithSideButtonSingle
                            parameter={commutationProportion1987}
                            setParameter={callbackCommutationProportion1987}
                            parameterName={commutationPrevSchemeLabel}
                            parameterMinValue={0}
                            parameterMaxValue={1} 
                            parameterDisplayMultiplicationFactor={100}
                            parameterRoundingDP={0}
                            parameterStepSize={0.01}
                            parameterInformation={commutation1987Information}
                            requestUpdatedData={doNothing}
                        />}
                        {!equalised && maxCommutationCurrScheme[0] > 0 &&
                        <SliderWithSideButtonSingle
                            parameter={commutationProportion2015}
                            setParameter={callbackCommutationProportion2015}
                            parameterName="Cash lump sum in 2015 scheme (%)"
                            parameterMinValue={0}
                            parameterMaxValue={1} 
                            parameterDisplayMultiplicationFactor={100}
                            parameterRoundingDP={0}
                            parameterStepSize={0.01}
                            parameterInformation={commutation2015Information}
                            requestUpdatedData={doNothing}
                            />}
                        </div>
                        
                    </div>

                    <div className="description__container">
                        <div className="description__payments">
                            <h4> { titles[currentPanel] } </h4>
                            <p>
                            <Typography sx={{display:'block',textAlign:'left',fontSize:'16px', fontWeight:'bold'} }>
                                If you remain in your <u>legacy</u> Scheme until 2022  
                            </Typography>    
                            </p>
                            
                            <p> { introduction[currentPanel]['2022'] } </p>
                            <p>
                            <Typography sx={{display:'block',textAlign:'left',fontSize:'16px', fontWeight:'bold'}}>
                                If you move to the <u>reformed</u> Scheme in 2015  
                            </Typography>                                 
                            </p>
                           
                            <p> { introduction[currentPanel]['2015'] } </p>
                            <p> { careIntroduction[currentPanel]['2015'] } </p>
                        </div>
                        <div className="description__payments">
                            <h4> Lump Sum </h4>
                            <p> { finalSalaryCommutation[currentPanel]['2015'] } </p>
                            <p> { equalised ? careCommutationEqualised[currentPanel]['2015'] : careCommutation[currentPanel]['2015'] } </p>
                            <p> { equalised && careCommutationEqualised[currentPanel]['2022'] } </p>
                        </div>
                    </div>



                    <div className="button__container">
                        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, alignItems: 'center' }}>
                            <Box sx={{ flex: '1 0 auto' }} />
                            <Button style={{color:colourButton,border:colourButton,borderStyle:'solid',borderWidth:'1px'}} fullWidth={false} onClick={handleSubmit}>Continue</Button>
                        </Box>
                        <br></br>
                    </div>
                </div>
            ):(
                <div className="title__container">
                    <h3> Pension and lump sum amounts at Key Milestones </h3>
                    <p>This page is only applicable to active members of the scheme</p>
                    <p>If you have left the scheme, you will not be able to see the journey</p>
                    <p>Please navigate to Your Choice by clicking on the tab above</p>
                </div>
            )}
            
        </div>             
    )
}

export default YourSummary;